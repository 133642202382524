@use "src/_btn.scss";
@use "src/_theme.scss";
@use "src/_controls.scss";
@use "src/_inv.scss";
@use "src/_store.scss";
@use "src/_utils.scss";
@use "src/_bf.scss";

$red: #FF4A4A;
$rorange: #FF6A4D;
$orange: #FF9052;
$yorange: #FFB655;
$yellow: #FFD757;
body {
  @include theme.textFont;
  background: black;
}
strong, button, input {
  @include theme.altFont;
}

@include btn.Btn;
@include controls.Controls;
@include inv.Inv;
@include store.Store;
body {
  color: white;
}
button.mmbtn {
  position: fixed;
  top: 16px;
  right: 16px;
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
  font-size: 16px;
}

$h: 128px;
.header {
  position: fixed;
  top: 0;
  left: 0;
  &__img {
    img {
      height: $h;
    }
    display: inline-block;
     vertical-align: middle;
  } 
  &__text {
    height: $h;
    font-size: $h/4;
    display: inline-block;
     vertical-align: middle;
     line-height: $h;
  }

}
.core {
  position: fixed;
  top: $h;
  left: 0;
  padding: 16px;
  bottom: 0; 
  right: 0;
}
.pending {
  display: none;
  &--true {
    display: block;
  }
  position: fixed;
  top: 0;
  height: 64px;
  font-size: 24px;
  line-height: 64px;
  left: 0;
  width: 100%;
  text-align: center;
  color: black;
  background: white;
  z-index: 999;
}

$g: 16px;
.familiars {
  padding: $g;
}

$fw: 256px;
.familiar {
  display: inline-block;
  border: 1px solid white;
  border-radius: 4px;
  padding: $g;
  margin: $g;
  &__img {
    img {
      width: 90%;
      max-width: 640px;
      min-width: 50%;
    }
  }
  &__text {

  }
}

.transfer {
  margin-top: $g;
  input {
    border: none;
    border-bottom: 1px solid white;
    background: black;
    color: white;
    &:focus {
      outline: none;
      border-bottom: 1px solid $red;
    }
  }
}


button + button {
  margin-left: $g;
}

.core {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }


/* Track */
&::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
&::-webkit-scrollbar-thumb {
  background: $rorange;
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  background: #555;
}
}

.airdrop2Manager {
  ul {
    color: white;
    li {
      color: white;
    }
  }
  
}



p {
  margin: 0;
}